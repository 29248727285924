import React, { useEffect } from 'react';
import ReactModal from 'react-modal';

const ModalComponent = ({ isModal, closesModal, children, className }: any) => {
  useEffect(() => {
    if (isModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isModal]);
  return (
    <ReactModal
      isOpen={isModal}
      onRequestClose={closesModal}
      ariaHideApp={false}
      portalClassName={className}
      shouldCloseOnOverlayClick={true}
      style={{
        content: {
          position: 'absolute',
          border: 'none',
          outline: 'none',
          background: 'none',
        },
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.75)',
        },
      }}
    >
      <div className="modal-wrapper">
        <button type="button" className="modal-close" onClick={closesModal}>
          <span className="close">&#x2715;</span>
        </button>
        {children}
      </div>
    </ReactModal>
  );
};

export { ModalComponent };
